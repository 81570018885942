<template>
  <div style="background-color: #FAFAFA;padding-top: 20px;">
    <div style="height: auto;background-color: white;padding-bottom: 20px">
      <div class="center" style="padding: 30px 0;overflow: hidden">
        <div style="width: 320px;height: 100%;float: left;margin-right: 20px">
          <div style="height: 280px;border: 1px solid rgba(220, 220, 220, .5);margin-bottom: 20px">
            <img :src="imgIndexUrl" style="width: 320px;height: 280px;" alt=""/>
          </div>
          <div style="height: 45px;line-height: 45px">
            <div @click="changeImageIndex(false)" style="float: left;font-weight: bold;color: rgba(220, 220, 220, 1);cursor: pointer"><</div>

            <div
                 style="float:left;display: flex;width: 290px;margin: 0 auto;height: 45px;margin-right:5px;margin-left: 5px;justify-content: space-between">
              <img :class="{'select_img':imgIndex==index}" :src="item.url" alt="" v-for="(item,index) in goodsImage"
                   :key="index"
                   style="border: 1px solid rgba(220, 220, 220,.5);width: 45px;height: 45px" @click="chooseImage(index)">
            </div>
            <div @click="changeImageIndex(true)"
                 style="float: right;font-weight: bold;color: rgba(220, 220, 220,1);cursor: pointer">>
            </div>
          </div>
        </div>
        <div style="float: left;margin-right: 20px;width: 625px;">
          <div style="height: 110px">
            <div style="width: 430px;float: left">
              <div><p style="font-size: 20px;color: black">{{goods.name}}</p></div>
              <div style="margin-top: 10px"><p style="font-size: 14px">{{goods.introduction}}</p></div>
            </div>
          </div>
          <div style="height: 60px; background-color: rgb(250, 250, 250);padding: 0 10px;line-height: 60px">
            <div style="float: left">
              服务价格：<span style="color: rgb(255, 82, 0);font-size: 20px">￥{{goodsStock.price}}</span>
            </div>
            <div style="float: right">
              服务分类：<span style="color: #1890FF">{{classify}}</span>
            </div>
          </div>
          <div style="margin-top: 20px;margin-bottom: 10px;border: 1px solid #DCDCDC;border-top:none">
            <div style="height: 60px; padding: 0 10px;border-top: 1px solid #DCDCDC;" class="dp-flex flex-align-center" v-for="(item,index) in labelSpuVos" :key="index + item.id">
              <span>{{ item.name }}：</span>
              <span v-for="(child,cindex) in item.values" :key="cindex" @click="changeLabel(index,cindex)" :class="{'classify-area-choose':labelClass[index] == cindex}" style="cursor:pointer;border: 1px solid #DCDCDC;padding:4px 17px;margin-left:10px" >
                {{child.name}}
              </span>
            </div>
          </div>
          <div style="padding: 45px 0;display: flex">
            <div
              style="cursor:pointer;margin-right:20px;height: 35px;width: 180px;line-height: 35px;text-align: center;color: #1890FF;border: 1px solid #1890FF"
              @click="goPay">
              立即购买
            </div>
            <div
              @click="returnRoom"
              style="cursor:pointer;height: 35px;width: 180px;line-height: 35px;text-align: center;background-color: #1890FF;color: white">
              咨询商品
            </div>

          </div>
        </div>
        <div
          style="width: 210px;background-color: rgb(242, 242, 242);float: left;border-radius: 5px;padding: 20px 10px">
          <div>
            <span style="font-size: 14px;color: black;">{{shop.name}}</span>
          </div>
          <div style="height: 120px;display: flex;align-items: center;justify-content: center">
            <Avatar icon="ios-person" size="70" style="margin: 0 auto" :src="shop.imageUrl"/>
          </div>
          <!-- <div>
            <span v-show="shop.introduction" style="font-size: 14px;color: black;">店铺介绍:</span>
          </div>
          <div class="si_hang_sheng" style="margin-bottom: 15px">
            {{shop.introduction}}
          </div> -->
          <div style="margin-bottom: 10px">
            <span>服务质量</span>
            <Rate disabled v-model="shop.serviceQuality" />
          </div>
          <div style="margin-bottom: 10px">
            <span>服务态度</span>
            <Rate disabled v-model="shop.serviceAttitude" />
          </div>
          <div style="margin-bottom: 10px">
            <span>响应速度</span>
            <Rate disabled v-model="shop.serviceSpeed" />
          </div>
          <div
              @click="gotostore(shop.id,shop.shopTypeId)"
            style=";font-size: 12px; cursor:pointer;background-color:#1890FF;color: white;width: 80px;height: 20px;line-height: 20px;text-align: center;margin: 0 auto">
            进入店铺
          </div>
        </div>

        <div>

        </div>
      </div>
    </div>
    <div class="center bottom" style="background-color: white;margin-top: 20px;border: 1px solid rgb(220, 220, 220);margin-bottom: 20px">
      <span v-for="(item,index) in bottomList" :key="index" :class="{'bottom_select':bottomIndex == index}"
            @click="changeBottomIndex(index)">
        {{item}}
      </span>
      <div v-show="true"
           style="background-color:white;min-height: 500px;border-top: 1px solid rgb(220, 220, 220);border-bottom: 1px solid rgb(220, 220, 220)"
           class="comments">
        <div>
          <div style="padding: 35px" v-show="bottomIndex==0" v-html="goodsDetail.goodsDetail || '暂无信息'" class="html-box"></div>
          <div style="padding: 35px" v-show="bottomIndex==1" v-html="goodsDetail.serviceDetail || '暂无信息'" class="html-box"></div>
          <div v-show="bottomIndex==2" style="padding:0 40px">
            <div class="evaluate-label dp-flex" style="line-height: 50px">
              <div @click="changeEvaluateTab('')" style="cursor: pointer">全部评价（{{evaluateSum}}）</div>
              <div @click="changeEvaluateTab(3)" style="cursor: pointer">好评（{{evaluateSum3}}）</div>
              <div @click="changeEvaluateTab(2)" style="cursor: pointer">中评（{{evaluateSum2}}）</div>
              <div @click="changeEvaluateTab(1)" style="cursor: pointer">差评（{{evaluateSum1}}）</div>
            </div>
            <div v-for="(item,index) in evaluateList" :key="index" class="dp-flex evaluate-item flex-between" style="border-bottom: 1px solid #ddd; padding: 50px 0">
              <div style="width: 135px">
                <div><Rate disabled v-model="item.evaluateValue" /></div>
                <div>{{item.createdTime}}</div>
              </div>
              <div style="width: 715px">
                <div style="line-height: 26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="item.evaluateContent">{{item.evaluateContent}}</div>
                <div style="line-height: 26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-size: 12px" :title="item.replyContent"> <span v-if="item.replyContent">回复：</span> {{item.replyContent || '暂无回复'}}</div>
              </div>
              <div>{{item.userName}}</div>
            </div>
            <div STYLE="overflow: hidden;width:100%;margin: 10px 0">
              <Page
                :total="evaluateSum"
                :page-size="evaluateQuery.pageSize"
                show-elevator
                style="margin-top: 30px; float: right; margin-bottom: 30px"
                @on-change="changeEvaluatePage"
              />
            </div>
          </div>
          <div v-show="bottomIndex==3" style="width: 870px;margin-left: 100px;padding-top: 30px">
            <div v-for="(item,index) in commentList" :key="index" style="border-bottom: 1px dashed #ddd;">
              <div style="font-size: 18px;line-height: 20px;margin: 5px auto 10px;">
                <span style="color: #1890FF;">{{item.reviewUserName}}:</span>
                <span>{{item.reviewContent}}</span>

              </div>
              <div style="font-size: 16px;line-height: 20px;margin: 5px auto 10px;">
                <span style="color: #1890FF;">{{item.shopName}}:</span>
                <span>{{item.replyContent}}</span>
              </div>
            </div>
            <div v-if="commentList.length == 0" style="padding-top: 15px">
              暂无咨询
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    sendComment,
    getComment,goodsRest
  } from "@/plugins/api/Good";
  import { getEvaluateClassify,getScienceServiceDetail, getScienceServiceEvaluateList,getGoodsId } from "../../../plugins/api/scienceServiceApi";
  import {getLocalToken} from "../../../plugins/tools/storage";
  import {errorToast,successToast} from "../../../plugins/tools/util";

  export default {
    name: "ServiceDetail",
    data() {
      return {
        commentsIndex: 1,
        imgIndex: 0,
        imgIndexUrl: '',
        bottomIndex: 0,
        classifyIndex:0,
        areaIndex:0,
        bottomList: [
          '商品详情', '服务详情' //, '商品评价', '商品咨询'
        ],
        valueDisabled: 2,
        goodId: 0,
        goods: '',
        goodsDetail: '',
        goodsImage: [],
        labels:[],
        labelClass:[],
        labelSpuVos:[],
        classifyLables:[],
        areaLables:[],
        goodsStock: '',
        classify: '',
        shop: '',
        textareaVal: '',
        commentList:[],
        evaluateList: [],
        evaluateQuery: {
          pageSize: 8,
          pageNum: 1,
          evaluateLevel: ''
        },
        evaluateSum: 0,
        evaluateSum1: 0,
        evaluateSum2: 0,
        evaluateSum3: 0
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {

          if (JSON.stringify(to.query) !== '{}') {

            this.goodId = to.query.goodId;
            this.getGoodDetail(to.query.goodId);
          }
        }
      }
    },
    methods: {
      changeLabel(index,cindex){
        this.$set(this.labelClass,index,cindex)

      },
      gotostore(shopId, shopTypeId) {
        this.$router.push({
          path: "OutsideStore",
          query: {
            shopId: shopId,
          },
        });
      },
      checkLogin(){
        if(!getLocalToken()){
          this.$Modal.confirm({
            draggable: true,
            onOk: async () => {
              this.$router.push({name:'login'})
            },
            render: (h) => {
              return h('div',
                [h('div', {
                  style: "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;",
                }, '请先登录后再试')
                ])
            }
          })
          return false
        }else{
          return true
        }
      },
      returnRoom: function () {
        const state = this.checkLogin()
        if(!state){
          return
        }

        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.sendComment()
          },
          render: (h) => {
            return h('div',
              [h('div', {
                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              }, '咨询内容'),
                h('Input', {
                  style: "width:100%;",
                  props: {
                    value: this.textareaVal,
                    autofocus: true,
                    placeholder: '请输入咨询内容',
                    type: 'textarea',
                    autosize: true
                  },
                  on: {
                    input: (val) => {
                      this.textareaVal = val;
                    }
                  }
                })
              ])
          }
        })
      },
      //发送咨询
      sendComment() {
        sendComment({
          goodsId: this.goodId,
          reviewContent: this.textareaVal
        }).then(res => {
          if (res.code === 0){
            this.textareaVal = ""
            successToast("咨询成功")
          }
            // this.$Modal.success({
            //   title: '提示',
            //   content: '发送成功'
            // });
        })
      },
      //商品详情
      getGoodDetail(goodId) {
        getScienceServiceDetail({'goodsId': goodId}).then((res) => {
          if (res.code === 0) {
            this.goods = res.result.goods
            this.spu = res.result.goods.spu;
            this.classify = res.result.classifyTwo;
            this.labels = res.result.labels;
            this.labelSpuVos = res.result.labelSpuVos
            for(let i =0 ;i<this.labelSpuVos.length;i++){
              for(let j= 0;j<this.labelSpuVos[i].values.length;j++){
                if(this.labelSpuVos[i].valueId == this.labelSpuVos[i].values[j].id){
                  this.labelClass.push(j);
                  break;
                }
              }

            }


            this.goodsDetail = res.result.goodsDetail

            this.goodsImage = res.result.goodsImage
            this.imgIndexUrl = res.result.goods.mainImageUrl
            this.goodsStock = res.result.goodsStock
            if(res.result.shop){
              this.shop = res.result.shop
              if(!this.shop.serviceQuality){
                this.shop.serviceQuality = 5;
              }
              if(!this.shop.serviceAttitude){
                this.shop.serviceAttitude = 5
              }
              if(!this.shop.serviceSpeed){
                this.shop.serviceSpeed = 5
              }
            }

          }
        })
      },
      // 评价列表
      async getServiceEvaluate(param) {
        let pm = Object.assign({}, param, {goodsId: this.goodId});
        getScienceServiceEvaluateList(pm).then(res => {
          if (res.code === 0) {
            this[`evaluateSum${this.evaluateQuery.evaluateLevel}`] = res.result.total;
            this.evaluateList = res.result.list;
          }
        });
        let data = {
          goodsId:this.goodId
        }
        let json = await getEvaluateClassify(data)
        if(json.code == 0){
          this.evaluateSum3 = json.result.level3;
          this.evaluateSum1 = json.result.level1;
          this.evaluateSum2 = json.result.level2;
          this.evaluateSum = Number(json.result.level3) + Number(json.result.level2) + Number(json.result.level1)
        }
      },
      changeEvaluatePage(index) {
        this.evaluateQuery.pageNum = index;
        let param = Object.assign({}, this.evaluateQuery);
        this.getServiceEvaluate(param);
      },
      changeEvaluateTab(level) {

        this.evaluateQuery = {
          evaluateLevel: level,
          pageSize: 8,
          pageNum: 1
        };
        this.getServiceEvaluate(this.evaluateQuery);
      },
      //咨询留言列表
      getComment(goodId){
        getComment({'goodsId': goodId}).then((res) => {
          if (res.code === 0) {
            this.commentList = res.result.list
          }
        })
      },
      changeBottomIndex(index) {
        if(index == 2 || index == 3){
          const state = this.checkLogin()
          if(!state){
            return
          }
          index == 2 && this.getServiceEvaluate({goodsId: this.goodId});
          index == 3 && this.getComment(this.goodId);
        }
        this.bottomIndex = index
      },
      chooseComments(index) {
        this.commentsIndex = index
      },
      chooseImage(index){
        this.imgIndex = index;
        this.imgIndexUrl = this.goodsImage[this.imgIndex].url
      },
      changeImageIndex(flag) {
        let max = this.goodsImage.length - 1
        if (flag === true) {
          if (this.imgIndex < max) {
            this.imgIndex += 1
          } else {
            this.imgIndex = 0
          }
        }
        if (flag === false) {
          if (this.imgIndex !== 0) {
            this.imgIndex -= 1;
          } else {
            this.imgIndex = max
          }
        }
        this.imgIndexUrl = this.goodsImage[this.imgIndex].url

      },
      async goPay() {
        let value = ""
        for(let i = 0;i<this.labelSpuVos.length;i++){
          value +=this.labelSpuVos[i].id + ',' + this.labelSpuVos[i].values[this.labelClass[i]].id + ';'
        }
        value = value.slice(0,value.length-1)
        let data = {
          spu:this.spu,
          labelIdAndValue:value
        }
        let pjson = await getGoodsId(data)
        if(pjson.code == 0){

          let goodId = pjson.result
          let json = await goodsRest({goodsId:goodId,num:1})
          if(json.code ==0){
            if(json.result == true){
              this.$router.push({
                path: `servicePay?goodId=${goodId}`,
              });
            }else{
              errorToast(json.message)
            }
          }else{
            errorToast(json.message)
          }
        }else{
          errorToast(pjson.message)
        }


      }
    }
  }
</script>

<style scoped lang="scss">
  /deep/ .ivu-rate-star {
    margin-right: 2px;
  }

  .bottom_select {
    border-top: 1px solid #1890FF !important;
    color: #1890FF!important;
  }
  /deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
    /*background-color: #1890FF;*/
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }
  .comments_select {
    color: black;
  }

  .comments > span {
    display: inline-block;
    margin-left: 40px;
    line-height: 50px;
    font-size: 14px;
    cursor: pointer;
  }

  .bottom > span {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    border: 1px solid rgb(220, 220, 220);
    width: 140px;
    height: 40px;
    line-height: 40px;
  }

  .si_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .select_img {
    border: 1px solid #1890FF !important;
  }

  .classify-area-choose {
    border: 2px solid  #1890FF!important;
    position: relative;
  }
  .classify-area-choose:after {
    content: "";
    font-size: 12px;
    position: absolute;
    color: white;
    bottom: 0;
    right: -2px;
    text-align: center;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-color:  transparent #1890FF #1890FF  transparent ;
  }
  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .html-box > > > img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    height: auto !important;
  }

  .html-box > > > p {
    max-width: 100%;
    word-break: break-all;
    font-size: 15px !important;
  }

  .html-box > > > span {
    max-width: 100%;
    word-break: break-all;
    font-size: 15px !important;
  }

  .html-box > > > table {
    width: 100%;
  }
  .evaluate-label{
    &>div{
      margin-right: 50px;
    }
  }

</style>
<style>
  textarea.ivu-input {
    height: 120px !important;
  }
</style>
