/*
 * @Author: liu
 * @Date: 2021-03-29 13:32:32
 * @LastEditTime: 2021-04-01 10:33:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\plugins\api\scienceServiceApi.js
 */
import baseUrl, { indexUrl } from "../http/baseUrl";
import { get } from "../http/http";

//科技服务首页楼层
export const getScienceServiceIndex = () => {
    return get(`${indexUrl}/v1/template/goods`, { hideLoading: true })
}

//服务商品列表
/**
 * @description: 
 * @param {*} code 分类编号
 * @param {*} labelId 标签编号
 * @param {*} sortField （NONE:默认； PRICE:价格; SALES:销量）
 * @param {*} sortOrder （DESC:降序;ASC:升序）
 * @param {*} shopId 店铺编号
 * @param {*} pageSize
 * @param {*} pageNum
 * @return {*}
 */
export const getScienceServiceList = ({ 
    code = '', 
    labelId = '', 
    sortField = 'NONE', 
    sortOrder = 'DESC', 
    shopId = '', 
    pageSize = 15, 
    pageNum = 1 }) => {
    return get(`${baseUrl}/v1/goods/service`, {
        code,
        labelId,
        sortField,
        sortOrder,
        shopId, 
        pageSize, 
        pageNum
    }, { hideLoading: true })
}

//服务商品详情
export const getScienceServiceDetail = ({goodsId = ''}) => {
    return get(`${baseUrl}/v1/goods/service/detail`, {goodsId}, { hideLoading: true })
}

//服务商品评价
/**
 * @description: 
 * @param {*} goodsId 商品编号
 * @param {*} evaluateLevel 商品评价等级（1差评、2中评、3好评）
 * @param {*} userId 评价人编号
 * @param {*} pageSize
 * @param {*} pageNum
 * @return {*}
 */
export const getScienceServiceEvaluateList = ({
    goodsId = '',
    evaluateLevel = '',
    userId = '',
    pageSize = 8,
    pageNum = 1}) => {
    return get(`${baseUrl}/v1/goods/service/evaluate`, {
        goodsId,
        evaluateLevel,
        userId,
        pageSize,
        pageNum
    }, { hideLoading: true });
}

//二级分类编号获取服务商品规格列表
/**
 * @description: 
 * @param {*} classifyCode 分类编号
 * @return {*}
 */
export const getScienceServiceLabelList = ({classifyCode = ''}) => {
    return get(`${baseUrl}/v1/goods/service/label`, {classifyCode}, { hideLoading: true });
}
//服务分类标签
/**
 * @description: 
 * @param {*} type 科技服务分类SERVICE
 * @return {*}
 */
export const getClassifyCodeList = ({type = 'SERVICE'}) => {
    return get(`${baseUrl}/v1/classify/all`, {type}, { hideLoading: true });
}


export const getServiceNum = (params) => {
    return get(`${baseUrl}/v1/shop/service/count`,params)
}

export const getGoodsId = (params) => {
    return get(`${baseUrl}/v1/goods/service/getGoodIdByLabel`,params)
}

export const getEvaluateClassify = (params) =>{
    return get(`${baseUrl}/v1/goods/service/evaluate/total`,params)
}